import * as React from "react";
import Layout from "../components/Layout";
import "../styles/partner.css";

const PartnersPortal = () => (
  <Layout>
    <main>
      <div className="oe_website_login_container">
        <form
          className="oe_login_form"
          role="form"
          method="post"
          onsubmit="this.action = this.action + location.hash"
          action="/web/login"
        >
          <input
            type="hidden"
            name="csrf_token"
            defaultValue="71706d9d3490cdbb72d652520ef3f161435aad6co1640861413"
          />
          <div className="form-group field-login">
            <label htmlFor="login">Email</label>
            <input
              type="text"
              placeholder="Email"
              name="login"
              id="login"
              required="required"
              autofocus="autofocus"
              autoCapitalize="off"
              className="form-control "
            />
          </div>
          <div className="form-group field-password">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              id="password"
              required="required"
              autoComplete="current-password"
              maxLength={4096}
              className="form-control "
            />
          </div>
          <div className="clearfix oe_login_buttons text-center mb-1 pt-3">
            <button type="submit" className="btn btn-primary btn-block">
              Log in
            </button>
            <div className="justify-content-between mt-2 d-flex small">
              <a href="/web/reset_password">Reset Password</a>
            </div>
            <div className="o_login_auth" />
          </div>
          <input type="hidden" name="redirect" />
        </form>
        <div id="wrap" className="oe_structure oe_empty">
          <section className="s_three_columns pt32 pb32 bg-100 oe_custom_bg">
            <div className="container">
              <div className="row d-flex align-items-stretch">
                <div className="s_col_no_bgcolor pt16 pb16 col-lg-6">
                  <div className="card bg-white">
                    <div className="card-body" style={{}}>
                      <h3
                        className="card-title"
                        style={{
                          textAlign: "center",
                          color: "rgb(33, 53, 100)",
                        }}
                      >
                        THIS IS A LIMITED ACCESS SPACE FOR:
                      </h3>
                      <ul>
                        <h5 className="card-text">
                          <b style={{ color: "rgb(33, 53, 100)" }}>
                            JOBS' grantees
                          </b>{" "}
                          - login to view the progress of your grant
                          implementation and submit milestones
                        </h5>
                        <h5 className="card-text">
                          <b style={{ color: "rgb(33, 53, 100)" }}>
                            JOBS’ grant applicants
                          </b>{" "}
                          – login to complete or check the status of your grant
                          application
                        </h5>
                        <h5 className="card-text">
                          <b style={{ color: "rgb(33, 53, 100)" }}>
                            JOBS' BDSP subcontractors
                          </b>{" "}
                          - login to view and apply for contracting
                          opportunities and manage implementation of contracts
                        </h5>
                        <h5 className="card-text">
                          <b style={{ color: "rgb(33, 53, 100)" }}>
                            JOBS’ internal users
                          </b>
                        </h5>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="s_col_no_bgcolor pt16 pb16 col-lg-6">
                  <div className="card bg-white">
                    <div className="card-body" style={{}}>
                      <h3
                        className="card-title"
                        style={{
                          textAlign: "center",
                          color: "rgb(33, 53, 100)",
                        }}
                      >
                        INTERESTED IN APPLYING FOR GRANTS?
                      </h3>
                      <div className="s_btn pt16 pb16 text-center">
                        <a
                          href="/grants"
                          className="btn btn-delta rounded-circle o_default_snippet_text"
                          title="Grants Overview Page"
                        >
                          Click here to view available opportunities.
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  </Layout>
);

export default PartnersPortal;
